import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
//import Jitems from "src/assets/items.json";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss']
})
export class HubComponent {

  isPrivate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  items = [];
  filteredItems;

  constructor(private _router: Router, private http: HttpClient, private route: ActivatedRoute) {
    // console.log(_router.url):
    if (_router.url.includes('p')) {
      this.isPrivate.next(true);
    }
    this.route.paramMap.subscribe(param => {
      if (_router.url.includes('p')) {
        this.isPrivate.next(true);
      } else {
        this.isPrivate.next(false);
      }
    });
    this.http.get<any>('/assets/items.json').subscribe(async res => {
      let storage = JSON.parse(localStorage.getItem('groups'));
      for (let item of res) {
        if (this.isArray(item.content)) {
          if (storage) {
            if (storage[item.name]) {
              item.isOpen = storage[item.name];
            }
          }
          if (!item.isOpen) {
            if (item.defaultOpen) {
              item.isOpen = true;
            } else {
              item.isOpen = false;
            }
          }
          for (let itemc of item.content) {
            itemc.parent = item.name;
            itemc.color = itemc.color || 'grey';
            itemc.groupColor = item.groupColor || 'orangered';
          }
        }

        item.content.color = item.content.color || 'grey';

        this.items.push(item);
        console.log(this.items);
      }
      await this.updateItems(this.isPrivate.value);
      this.autoOpen();
    });

    this.isPrivate.subscribe(v => this.updateItems(v));
  }

  async updateItems(value) {
    if (value) {
      this.filteredItems = this.items;
    } else {
      //this.filteredItems = this.items.filter(s => !(s.content.isPrivate || s.isPrivate));
      this.filteredItems = await this.asyncFilter(this.items, async s => !(s.content.isPrivate || s.isPrivate));
    }
  }

  autoOpen() {
    let toOpen = [];
    for (const v of this.filteredItems) {
      if (v.isOpen) {
        console.log(`pushed ${v.name} to Opens`);
        toOpen.push(v.name);
      }
    }

    for (const i2 of toOpen) {
      for (const [i, v] of this.filteredItems.entries()) {
        if (v.name === i2) {
          console.log(`openend ${v.name}`)
          this.openGroup(v, i + 1);
          break;
        }
      }
    }
  }

  isArray(item) {
    return Array.isArray(item);
  }

  open(item, index) {
    item.isOpen = !item.isOpen;
    this.updateLocalStorage(item.name, item.isOpen);
    if (item.isOpen) {
      this.openGroup(item, index + 1);
    } else {
      this.closeGroup(item);
    }
  }

  openGroup(item, index) {
    for (const [i, v] of item.content.entries()) {
      const vc = { content: v };
      this.filteredItems.splice(index + i, 0, vc);
    }
  }

  closeGroup(item) {
    this.filteredItems = this.filteredItems.filter(s => s.content.parent !== item.name);
  }

  updateLocalStorage(item, state) {
    let storage = JSON.parse(localStorage.getItem('groups')) || {};
    storage[item] = state;
    localStorage.setItem('groups', JSON.stringify(storage));
  }

  asyncFilter = async (arr, predicate) => {
    const results = await Promise.all(arr.map(predicate));

    return arr.filter((_v, index) => results[index]);
  }
}