import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  @Input() name;
  @Input() isGroup;
  @Input() link;
  @Input() img;
  @Input() color;
  @Input() imgWidth;
  @Input() isLock;
  @Input() isPrivate;
  @Input() parent;
  @Input() groupColor;



  constructor() {

  }

  ngOnInit(): void {
    console.log(this.parent);
    console.log('item name : ', this.name, ' / isGroup : ', this.isGroup, ' / groupColor : ', this.groupColor);
  }

}
