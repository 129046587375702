<a *ngIf="!isGroup" [href]="link" [class.troplaclasse]="parent !== null">
    <div *ngTemplateOutlet="body"></div>
</a>
<div *ngIf="isGroup" class="groupe">
    <div *ngTemplateOutlet="body"></div>
</div>

<ng-template #body>
    <h1 class="hoverable" [ngStyle]="{'color': !isGroup ? 'white' : (groupColor || 'aqua')}">{{name}}</h1>
    <div class="hexaContainer" [ngStyle]="{'background-color': groupColor}"
        [class.noBorder]="!isGroup && parent === null">
        <span class="before" [ngStyle]="{'border-bottom-color': groupColor}"></span>
        <span class="after" [ngStyle]="{'border-top-color': groupColor}"></span>
        <div class="hexa" [ngStyle]="{'background-color': color, 'border-color': groupColor}" [id]="name">
            <span class="before" [ngStyle]="{'border-bottom-color': color}"></span>
            <span class="after" [ngStyle]="{'border-top-color': color}"></span>
            <div class="content">
                <img class="img" [src]="img" [ngStyle]="{ 'width' : imgWidth }" />
                <img *ngIf="isLock" class="private" src="\assets\img\lock.png">
                <img *ngIf="isPrivate" class="private" src="\assets\img\priv_nav.png">
            </div>
        </div>
    </div>
    <!-- <div class="hexagone">
        <div class="hexagonemain">
            <div class="hexagonemainbefore" [ngStyle]="{'background-color': color }" [id]="name">
                <img class="img" [src]="img" [ngStyle]="{ 'width' : imgWidth }">
                <img *ngIf="isLock" class="private" src="\assets\img\lock.png">
                <img *ngIf="isPrivate" class="private" src="\assets\img\priv_nav.png">
            </div>
        </div>
    </div> -->
</ng-template>