<div id="bite_container">
    <div class="common" *ngFor="let item of filteredItems; let i = index">
        <app-item *ngIf="!isArray(item['content'])" [name]="item['content'].name" [link]="item['content'].link"
            [img]="item['content'].img" [color]="item['content'].color" [imgWidth]="item['content'].imgWidth"
            [isLock]="item['content'].isLock" [isPrivate]="item['content'].isPrivate" [isGroup]="false"
            [ngClass]="{'troplaclasse' : (item['content'].parent !== null || item['content'].parent === undefined)}"
            [parent]="(item['content'].parent !== null && item['content'].parent !== undefined) ? item['content'].parent : null" [groupColor]="item['content'].groupColor">
        </app-item>
        <app-item *ngIf="isArray(item['content'])" (click)="open(item, i)" [name]="item.name" [isGroup]="true"
            [img]="item.img" [color]="item.color" [imgWidth]="item.imgWidth" [isLock]="item.isLock"
            [isPrivate]="item.isPrivate" [groupColor]="item.groupColor">
        </app-item>
    </div>
</div>
